import { FC, lazy } from 'react';
import VersionedComponent from '../../../shared/VersionedUi/VersionedComponent';
import useUiVersion from '../../../hooks/useUiVersion';
import classnames from 'classnames';

const HomepageSliderV1 = lazy(() => import('../../Homepage/HomepageSlider'));
const HomepageSliderV2 = lazy(
  () => import(/* webpackChunkName: "homepageSliderV2" */ './HomepageSliderV2')
);
const HomepageSliderV3 = lazy(
  () => import(/* webpackChunkName: "homepageSliderV3" */ './HomepageSliderV3')
);
const HomepageSliderVersioned = () => {
  const { version } = useUiVersion();

  return (
    <div className={classnames({ 'new-ui': version === 'v2' })}>
      <VersionedComponent v1={HomepageSliderV1} v2={HomepageSliderV3} />
    </div>
  );
};

export default HomepageSliderVersioned;
